@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for Firefox */
  * {
    scrollbar-width: none; /* Firefox */
  }